import Vue from 'vue'
import {EagleListConfigInterface, TableDataType} from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    // @ts-ignore
    return {
      pageTitle: 'module.site_admin',
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_ADMIN']),
      search: 'site_admin.search_keyword',
      filter: {
        locked: {
          label: 'site_admin.data.locked',
          type: 'selection',
          options: [false, true],
          optionTextPrefix: 'site_admin.data.locked',
        },
        roles: {
          label: 'site_admin.data.roles',
          type: 'selection',
          options: (listMeta: AnyObject|null) => !listMeta ? [] : listMeta.roles,
          multiple: true,
        },
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      exportExcel: {
        filename: (time: string) => `${this.vm?.$t('module.site_admin')}`,
        sheets: [
          {
            label: 'module.site_admin',
            data: {
              id: { label: 'ID', width: 20 },
              name: { label: 'data.name', width: 18 },
              email: { label: 'data.name', width: 22 },
              avatar: {
                label: 'data.avatar',
                value: row => this.vm?.$helper.getPhotoUrl(row.avatar, ['origin']),
              },
              locked: {
                label: 'site_admin.data.locked',
                value: row => this.vm?.$t(`data.${!!row.locked}`),
                width: 12,
              },
              maintainer: {
                label: 'site_admin.data.maintainer',
                value: row => this.vm?.$t(`data.${!!row.maintainer}`),
                width: 12,
              },
              created_at: { label: 'data.created_at', width: 18 },
            },
          },
        ]
      },
      selectedData: row => ({
        id: row.id,
        name: this.vm?.$helper.getSiteUserName(row),
        email: row.email,
      }),
      displayMode: 'table',
      displayModeTypes: ['card', 'table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
        { label: 'site_admin.data.email', key: 'email' },
      ],
      card: {
        avatarPhoto: row => row.avatar,
        title: row => this.vm?.$helper.getSiteUserName(row),
        subtitle: row => row.email,
      },
      table: {
        data: <TableDataType>[
          {
            key: 'avatar',
            label: 'module.site_admin',
            type: 'photo',
            placeholderIcon: 'fa fa-user-circle',
          },
          {
            key: 'profile',
            label: 'site_admin.data.name_email',
            type: 'text',
            isHtml: true,
            text: row => {
              const email = row.email
              const name = row.name
              if (!name) return email
              return `${name}<br>${email}`
            },
            tooltipContent: row => {
              const email = row.email
              const name = row.name
              if (!name) return email
              return `${name}<br>${email}`
            },
            maxWidth: '200px',
          },
          {
            key: 'locked',
            label: 'site_admin.data.locked',
            type: 'switch',
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        lock: {
          label: 'site_admin.lock',
          column: 'locked',
          value: true,
          targetLabel: row => window.helper.getSiteUserName(row),
        },
        unlock: {
          label: 'site_admin.unlock',
          column: 'locked',
          value: false,
          targetLabel: row => window.helper.getSiteUserName(row),
        },
        delete: {
          targetLabel: row => window.helper.getSiteUserName(row),
        },
      },
      metaAction: {
        create: {
          create: (hasWritePermission) => hasWritePermission,
          label: 'action.create',
          linkTarget: '_self',
          type: 'route',
          route: () => ({ name: 'site-admin-create' }),
        },
      },
      dataAction: {
        update: {
          label: 'action.edit',
          create: (row: AnyObject, hasWritePermission: boolean) => hasWritePermission,
          route: row => ({ name: 'site-admin-update', params: { target: row.id } }),
          linkTarget: '_self',
        },
        delete: {
          create: (row: AnyObject, hasWritePermission: boolean) => hasWritePermission,
          removeTarget: row => this.vm?.$helper.getSiteUserName(row),
        },
        resetPassword: {
          create: (row: AnyObject, hasWritePermission: boolean) => hasWritePermission,
          label: 'action.reset-password',
          color: 'orange lighten-1',
          buttonProperties: (row: any) => ({
            class: 'd-block white--text',
          }),
          // @ts-ignore
          callback: row => this.vm.resetPassword(row),
        },
      },
    }

  }
}

export default new listConfig()
